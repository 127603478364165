import { useEffect, useState } from "react";
import { Form, FormInstance } from "antd";

interface IUseFormValidation {
  isDisabled: boolean;
}

const useFormValidation = (form: FormInstance): IUseFormValidation => {
  const [isDisabled, setIsDisabled] = useState<boolean>(true);
  const values = Form.useWatch([], form);

  useEffect(() => {
    const allFieldsFilled: boolean = Object.values(form.getFieldsValue()).every((value: any) => !!value);

    if (allFieldsFilled) {
      form.validateFields()
        .then(() => setIsDisabled(false))
        .catch(() => setIsDisabled(true));
    } else {
      setIsDisabled(true);
    }
  }, [values]);

  return { isDisabled };
};

export default useFormValidation;
import React, { FC } from "react";

import { Button, InputProps } from "antd";

import css from "./style.modules.scss";

export const defaultInputProps: InputProps = {
  className: "flex md:w-0 md:grow-1 py-3 px-3",
  size: "large",
  style: { borderRadius: "4px" },
};

interface IAuthLayoutProps {
  isVisible?: boolean;
  title: string;
  message: string;
  buttonText: string;
  onButtonClick: () => void;
  buttonDisabled?: boolean;
  actions?: JSX.Element | JSX.Element[];
  formSize?: "small" | "large"
}

const AuthLayout: FC<IAuthLayoutProps> = ({
  isVisible = true,
  title,
  message,
  buttonText,
  onButtonClick,
  buttonDisabled,
  actions,
  formSize = "small",
  children
}) => {

  return isVisible && (
    <div className={`flex min-h-full h-100 w-full mobile:p-3 justify-center ${css.layoutWrapper}`}>
      <div className={`flex flex-col justify-center gap-3 w-full ${css.formWrapper} ${css[formSize]}`}>
        <div className="flex flex-col items-center gap-3">
          <h1 className="text-x3">{title}</h1>
          <h2 className="text-n2 text-center text-blue-grey">{message}</h2>
        </div>
        <div className="flex flex-col gap-3">
          {children}
          <Button
            className={`w-full ${css.button}`}
            size="large"
            type="primary"
            onClick={onButtonClick}
            disabled={buttonDisabled}
          >
            {buttonText}
          </Button>
          {actions}
        </div>
      </div>
    </div>
  );
};

export default AuthLayout;